import { Box, Toolbar } from '@xbotvn/react-ui/core';
import styled from 'styled-components';

export const GroupedItems = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})``;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  && {
    min-height: 40px;
  }
`;
