import React, { useEffect, useMemo, useState } from 'react';

import { Table, Waiting } from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
  DialogActions,
  Button,
} from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { handleUpdateCities } from '../../redux/actions/cities';

const cleanText = (name = '') => name.toLowerCase()
  .replace(/thành phố/g, '')
  .replace(/tp/g, '')
  .replace(/-/g, '')
  .replace(/\s\s+/g, ' ')
  .replace(/tỉnh/g, '')
  .replace(/huyện/g, '')
  .replace(/thị xã/g, '')
  .replace(/quận/g, '')
  .replace(/phường/g, '')
  .replace(/xã/g, '')
  .replace(/thị trấn/g, '')
  .trim();

function Mapping({
  onClose,
  onReload,
  app,
  source,
  dest,
}) {
  const dispatch = useDispatch();

  const [mapped, setMapped] = useState({});
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const tmp = {};
    source.forEach((item) => {
      const search = cleanText(item.name);
      const found = dest.find(({ name }) => cleanText(name) === search);
      if (found) {
        tmp[item.id] = found.id;
      }
    });
    setMapped(tmp);
  }, [source, dest]);
  const columns = useMemo(() => [
    {
      Header: 'Official',
      columns: [
        {
          Header: 'Mã',
          accessor: 'id',
          width: 120,
          disableEdit: true,
        },
        {
          Header: 'Tên',
          accessor: 'name',
          width: 155,
          disableEdit: true,
        },
      ],
    },
    {
      Header: app.toUpperCase(),
      columns: [
        {
          Header: 'Tên',
          accessor: 'name_dest',
          width: 155,
          disableEdit: true,
        },
        {
          Header: 'Mã',
          accessor: 'id_dest',
          width: 120,
        },
      ],
    },
  ], []);

  const {
    missing,
    rows,
  } = useMemo(() => {
    const results = [];
    const passed = [];
    source.forEach((item) => {
      const result = {
        ...item,
      };
      const destID = mapped[item.id];
      if (destID) {
        const found = dest.find(({ id }) => destID === id);
        if (found) {
          result.name_dest = found.name;
          result.id_dest = destID;
          passed.push(found.id);
        }
      }
      results.push(result);
    });
    return {
      rows: results,
      missing: dest.filter(({ id }) => !passed.includes(id)),
    };
  }, [source, dest, mapped]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClose} title={`Kiểm tra mapping ${app.toUpperCase()}`} />
      <DialogContent dividers>
        {waiting ? <Waiting fullscreen /> : null}
        {missing.length ? (
          missing.map(({ id, name }) => (
            <Chip key={id} label={`${name} (${id})`} />
          ))
        ) : null}
        <Table
          columns={columns}
          data={rows}
          disableFilters
          disableGroupBy
          getRowId={(row) => row.id}
          height={window.innerHeight - 400}
          usePagination={false}
          updateHandler={(id, field, value) => {
            if (field === 'id_dest') {
              setMapped((prevMapped) => ({
                ...prevMapped,
                [id]: value,
              }));
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setWaiting(true);
            const cities = [];
            source.forEach((item) => {
              cities.push({
                id: item.id,
                [app]: mapped?.[item.id] ?? '',
              });
            });
            dispatch(handleUpdateCities(cities, (error) => {
              if (!error) {
                onClose();
                onReload();
              }
            }));
          }}
        >
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Mapping.propTypes = {
  onClose: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
  source: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  dest: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default Mapping;
