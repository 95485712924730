import React, { useEffect, useState } from 'react';

import { Waiting, AutoComplete } from '@xbotvn/react-ui/components';
import {
  Grid,

  Box,
} from '@xbotvn/react-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { APPS } from '../../config';
import { handleGetMapping } from '../../redux/actions/cities';
import Districts from './districts';
import Editor from './Editor';
import Provinces from './provinces';
import Status from './status';
import Wards from './wards';

function Cities() {
  const dispatch = useDispatch();
  const {
    mapping,
    handling,
  } = useSelector(({ cities }) => ({
    mapping: cities?.mapping ?? {},
    handling: cities?.handling ?? false,
  }));

  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [showMapping, setShowMapping] = useState();
  const [showEditor, setShowEditor] = useState();

  const common = {
    showMapping,
    onEdit: (id) => setShowEditor(id),
  };

  useEffect(() => {
    if (showMapping && !Object.keys(mapping?.[showMapping] ?? {}).length) {
      dispatch(handleGetMapping(showMapping));
    }
  }, [showMapping]);

  return (
    <div style={{ margin: 10 }}>
      {showEditor ? (
        <Editor onClose={() => setShowEditor()} id={showEditor} />
      ) : null}
      <Box display="flex" m={2} alignItems="flex-end">
        <Box flexGrow={1}>
          <Status province={province} district={district} />
        </Box>
        <Box width={200}>
          <AutoComplete
            value={showMapping}
            options={APPS}
            onChange={(e, value) => setShowMapping(value)}
            disableCloseOnSelect
            inputProps={{
              label: 'Mapping',
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        {handling ? <Waiting fullscreen /> : null}
        <Grid item xs={4}>
          <Provinces onSelect={setProvince} {...common} />
        </Grid>
        {province ? (
          <Grid item xs={4}>
            <Districts province={province} onSelect={setDistrict} {...common} />
          </Grid>
        ) : null}
        {district ? (
          <Grid item xs={4}>
            <Wards district={district} {...common} />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

export default Cities;
