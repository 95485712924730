import React, { useState } from 'react';

import { Notification, Waiting } from '@xbotvn/react-ui/components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  DialogActions,
  Button,
} from '@xbotvn/react-ui/core';
import { cloneDeep } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { APPS } from '../../config';
import { handleUpdateCities } from '../../redux/actions/cities';

function Editor({ onClose, id }) {
  const dispatch = useDispatch();
  const {
    initData,
    handling,
  } = useSelector(({
    cities,
  }) => ({
    initData: (cities?.data ?? []).find(({ id: cid }) => id === cid) || {},
    handling: cities?.handling ?? false,
  }));

  const [data, setData] = useState(cloneDeep(initData));

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle onClose={onClose} title={`${id} - ${initData.name}`} />
      <DialogContent dividers>
        {handling ? <Waiting fullscreen /> : null}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Mã"
              variant="outlined"
              required
              fullWidth
              value={data?.id ?? ''}
              onChange={(e) => setData((prevData) => ({
                ...prevData,
                id: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Tên"
              variant="outlined"
              required
              fullWidth
              value={data?.name ?? ''}
              onChange={(e) => setData((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))}
            />
          </Grid>
        </Grid>
        {APPS.map((app) => (
          <TextField
            key={app}
            label={app.toUpperCase()}
            variant="outlined"
            fullWidth
            value={data?.[app] ?? ''}
            onChange={(e) => setData((prevData) => ({
              ...prevData,
              [app]: e.target.value,
            }))}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (!data.id || !data.name) {
              Notification.warn('Tên và mã không được bỏ trống');
              return;
            }
            dispatch(handleUpdateCities([data], (err) => {
              if (!err) onClose();
            }));
          }}
        >
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Editor.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default Editor;
