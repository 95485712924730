import { all } from 'redux-saga/effects';

import citiesSaga from './cities';
import settingsSaga from './settings';
import userSaga from './user';

export default function* rootSaga() {
  yield all([
    userSaga(),
    settingsSaga(),
    citiesSaga(),
  ]);
}
