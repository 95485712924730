export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    signOut: 'USER_SIGNOUT_HANDLER',
  },
  update: 'USER_UPDATE',
};

export const CITIES = {
  handlers: {
    query: 'CITIES_QUERY_HANDLER',
    getMapping: 'CITIES_GET_MAPPING_HANDLER',
    update: 'CITIES_UPDATE_HANDLER',
  },
  update: 'CITIES_UPDATE',
  replace: 'CITIES_REPLACE',
  mapping: 'CITIES_MAPPING',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SIGN_OUT = 'SIGN_OUT';
