/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react';

import { Notification } from '@xbotvn/react-ui/components';
import {
  Box,
  Button,
} from '@xbotvn/react-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  app,
  googleProvider,
} from '../../libs/firebase';

function Welcome() {
  const history = useHistory();
  const { email, authorizing } = useSelector(({ user }) => user);
  useEffect(() => {
    if (!authorizing && email) {
      history.push('/cities');
    }
  }, [email, authorizing]);
  const errorHandler = (error) => {
    if (!error) return;
    if (error.code === 'auth/account-exists-with-different-credential') {
      Notification.warn(
        'Địa chỉ email này đã được đăng nhập bằng hình thức đăng nhập khác.',
      );
    } else {
      Notification.warn(`Lỗi kết nối: ${error}`);
    }
  };

  const authWithGoogle = useCallback(() => {
    app
      .auth()
      .signInWithPopup(googleProvider)
      .catch((error) => {
        errorHandler(error);
      });
  }, []);

  return (
    <Box position="fixed" left="48%" right="48%">
      <Button onClick={authWithGoogle} color="primary">
        Google
      </Button>
    </Box>
  );
}

export default Welcome;
