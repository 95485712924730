import { CITIES, SIGN_OUT } from '../actions/constants';

const initialState = {
};

export default function cities(state = initialState, action) {
  const {
    type,
    app,
    data,
  } = action;

  switch (type) {
    case CITIES.handlers.query:
    case CITIES.handlers.update:
    case CITIES.handlers.getMapping:
      return {
        ...state,
        handling: true,
      };
    case CITIES.update:
      return {
        ...state,
        data: [
          ...(state?.data ?? []),
          ...data,
        ],
        handling: false,
      };
    case CITIES.replace:
      return {
        ...state,
        data: [
          ...(state?.data ?? []).filter(({ id }) => data.find(({ id: did }) => did !== id)),
          ...data,
        ],
        handling: false,
      };
    case CITIES.mapping:
      return {
        ...state,
        mapping: {
          ...(state?.mapping ?? {}),
          [app]: data,
        },
        handling: false,
      };
    case SIGN_OUT:
      return {
      };
    default:
      return state;
  }
}
