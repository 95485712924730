import { Notification } from '@xbotvn/react-ui/components';
import {
  takeLatest,
  takeEvery,
  put,
  all,
} from 'redux-saga/effects';

import {
  auth,
  signedOut,
} from '../../libs/backend';
import { app } from '../../libs/firebase';
import { USER, CITIES, SIGN_OUT } from './constants';

export function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

function* userSignedIn(user) {
  const { email, uid } = user;
  if (email) {
    try {
      const {
        account,
      } = yield auth(email, uid);
      yield* update({
        uid,
        email,
        displayName: user?.displayName ?? email,
        photoURL: user?.photoURL ?? '',
        account,
        authorizing: false,
      });
      yield put({
        type: CITIES.handlers.query,
        filter: { type: 'province' },
      });
    } catch ({ message }) {
      yield* update({ email, uid, authorizing: false });
      Notification.error(message);
    }
  } else {
    yield* update({ authorizing: false });
  }
}

function* userSignedOut() {
  yield app.auth().signOut();
  yield signedOut();
  yield put({
    type: SIGN_OUT,
  });
}

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: USER.handlers.signOut,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeEvery(USER.handlers.signOut, userSignedOut),
  ]);
}
