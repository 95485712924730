import React, { useMemo } from 'react';

import { Typography, Box } from '@xbotvn/react-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function Status({ province, district }) {
  const data = useSelector(({ cities }) => cities?.data ?? []);

  const pName = useMemo(() => data.find(({ id }) => id === province)?.name, [province, data]);
  const dName = useMemo(() => data.find(({ id }) => id === district)?.name, [district, data]);
  return (
    <Box display="flex">
      <Typography variant="h6">{pName}</Typography>
      {dName ? (
        <Typography variant="h6">
          ,
          {' '}
          {dName}
        </Typography>
      ) : null}
    </Box>
  );
}

Status.propTypes = {
  province: PropTypes.string,
  district: PropTypes.string,
};

Status.defaultProps = {
  province: '',
  district: '',
};

export default Status;
