import React, { useMemo } from 'react';

import {
  AppBar as UAppBar,
  Icon,
  Avatar,
  Box,
  Button,
} from '@xbotvn/react-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as Styles from './styles';

function AppBar() {
  const history = useHistory();
  const {
    unit,
    email,
    account,
    photoURL,
    displayName,
  } = useSelector(({ user }) => user);

  const { isSupport, isStaff, isAdmin } = useMemo(() => ({
    isSupport: account?.xbot?.support ?? false,
    isStaff: (unit?.staffs ?? []).map((e) => e.email).includes(email),
    isAdmin: (unit?.admins ?? []).includes(email),
  }), [email, unit, account]);

  if (isStaff || isAdmin || isSupport) {
    return (
      <UAppBar position="static" style={{ background: 'white', padding: '12px 0' }}>
        <Styles.StyledToolbar>
          <Box flexGrow={1} display="flex">
            <img
              style={{ width: 50, height: 'auto' }}
              src="https://xbot.com.vn:3000/resources/logo.png"
              alt="logo"
            />
            <Box ml={5} display="flex">
              <Button
                size="small"
                color="secondary"
                onClick={() => history.push('/cities')}
                startIcon={(<Icon>place</Icon>)}
                style={{ marginRight: 10 }}
              >
                Đơn vị hành chính
              </Button>
            </Box>
          </Box>
          <Avatar
            alt={displayName || email} src={photoURL || ''}
            style={{ cursor: 'pointer', marginLeft: 8, marginRight: 10 }}
          />
        </Styles.StyledToolbar>
      </UAppBar>
    );
  }
  return null;
}

export default AppBar;
