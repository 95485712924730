import { Notification } from '@xbotvn/react-ui/components';
import {

  takeEvery,
  put,
  all,
} from 'redux-saga/effects';

import { graphQLCaller, callAPI } from '../../libs/backend';
import { CITIES } from './constants';

export function* update(data = []) {
  yield put({
    type: CITIES.update,
    data,
  });
}

export function* replace(data) {
  yield put({
    type: CITIES.replace,
    data,
  });
}

export function* mapping(app, data = {}) {
  yield put({
    type: CITIES.mapping,
    app,
    data,
  });
}

function* queryCities({ filter }) {
  try {
    const { getCities: cities } = yield graphQLCaller(
      'cities',
      `
        query getCities($filter: Filter) {
          getCities(filter: $filter) {
            id
            name
            type
            province
            district
            elibot
          }
        }
      `,
      {
        filter,
      },
    );
    yield* replace(cities);
  } catch ({ message }) {
    yield* update([]);
    Notification.error(message);
  }
}

function* getMappings({ app }) {
  try {
    const data = yield callAPI('api/getMapping', { app });
    yield* mapping(app, data || {});
  } catch ({ message }) {
    yield* mapping(app, {});
    Notification.error(message);
  }
}

function* updateCities({ data, onComplete }) {
  try {
    yield graphQLCaller(
      'cities',
      `
          mutation updateInformation($data: [CityInput]) {
            updateInformation(data: $data)
          }
        `,
      {
        data,
      },
    );
    yield* replace(data);
    Notification.success('Cập nhật thành công.', { action: onComplete });
  } catch ({ message }) {
    yield* replace([]);
    Notification.error(message, { action: () => onComplete(message) });
  }
}

export const handleQuery = (filter) => ({
  type: CITIES.handlers.query,
  filter,
});

export const handleGetMapping = (app) => ({
  type: CITIES.handlers.getMapping,
  app,
});

export const handleUpdateCities = (data, onComplete) => ({
  type: CITIES.handlers.update,
  data,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeEvery(CITIES.handlers.query, queryCities),
    yield takeEvery(CITIES.handlers.update, updateCities),
    yield takeEvery(CITIES.handlers.getMapping, getMappings),
  ]);
}
