import React, { useMemo, useState } from 'react';

import { Table } from '@xbotvn/react-ui/components';
import {
  Icon,
  IconButton,
  Paper,
  Box,
  Button,
  Typography,
} from '@xbotvn/react-ui/core';
import { orderBy } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { handleQuery } from '../../redux/actions/cities';
import Mapping from './mapping';

function Provinces({ onSelect, onEdit, showMapping }) {
  const dispatch = useDispatch();
  const {
    mappingData,
    data,
  } = useSelector(({ cities }) => ({
    mappingData: cities?.mapping?.[showMapping] ?? {},
    data: cities?.data ?? [],
  }));

  const [checkMapping, setCheckMapping] = useState(false);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Mã',
        accessor: 'id',
        width: 80,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <Box display="flex" alignItems="center">
            <IconButton size="small" onClick={() => onEdit(value)}>
              <Icon>edit</Icon>
            </IconButton>
            {value}
          </Box>
        ),
      },
      {
        Header: 'Tên',
        accessor: 'name',
        width: 200,
      },
    ];
    if (showMapping) {
      cols.push({
        Header: showMapping.toUpperCase(),
        accessor: showMapping,
        sticky: 'right',
      });
    }
    return cols;
  }, [showMapping]);

  const rows = useMemo(() => orderBy(data.filter(({ type }) => type === 'province'), ['name'], ['asc']), [data]);

  return (
    <Paper elevation={3}>
      {checkMapping ? (
        <Mapping
          onClose={() => setCheckMapping()}
          app={showMapping}
          source={rows}
          dest={Object.entries(mappingData).map(([id, values]) => ({ id, name: values?.name ?? '' }))}
        />
      ) : null}
      <Box display="flex" p={1}>
        <Box flexGrow={1}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>Tỉnh/Thành</Typography>
        </Box>
        {showMapping ? (
          <Button color="secondary" onClick={() => setCheckMapping(true)}>
            Kiểm tra mapping
          </Button>
        ) : null}
      </Box>
      <Table
        columns={columns}
        data={rows}
        disableFilters
        disableGroupBy
        onRowDoubleClick={async (row) => {
          onSelect(row.id);
          dispatch(handleQuery({ type: 'district', province: row.id }));
        }}
        getRowId={(row) => row.id}
        height={window.innerHeight - 340}
        usePagination={false}
      />
    </Paper>
  );
}

Provinces.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  showMapping: PropTypes.string,
};

Provinces.defaultProps = {
  showMapping: '',
};

export default Provinces;
