import { combineReducers } from 'redux';

import cities from './cities';
import settings from './settings';
import user from './user';

const rootReducer = combineReducers({
  user,
  settings,
  cities,
});

export default rootReducer;
