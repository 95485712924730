import React, { useMemo, useState } from 'react';

import { Table } from '@xbotvn/react-ui/components';
import {
  Paper,
  Typography,
  Box,
  Icon,
  IconButton,
  Button,
} from '@xbotvn/react-ui/core';
import { orderBy } from '@xbotvn/utils/collection';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { handleQuery } from '../../redux/actions/cities';
import Mapping from './mapping';

function Wards({ onEdit, district, showMapping }) {
  const dispatch = useDispatch();
  const {
    mappingData,
    data,
  } = useSelector(({ cities }) => ({
    mappingData: cities?.mapping?.[showMapping] ?? {},
    data: cities?.data ?? [],
  }));

  const districtData = useMemo(() => data.find(({ id, type }) => type === 'district' && id === district) ?? {}, [data]);
  const provinceData = useMemo(() => data.find(({ id, type }) => type === 'province' && id === districtData.province) ?? {}, [data]);

  const [checkMapping, setCheckMapping] = useState(false);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Mã',
        accessor: 'id',
        width: 120,
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <Box display="flex" alignItems="center">
            <IconButton size="small" onClick={() => onEdit(value)}>
              <Icon>edit</Icon>
            </IconButton>
            {value}
          </Box>
        ),
      },
      {
        Header: 'Tên',
        accessor: 'name',
        width: 200,
      },
    ];
    if (showMapping) {
      cols.push({
        Header: showMapping.toUpperCase(),
        accessor: showMapping,
        sticky: 'right',
      });
    }
    return cols;
  }, [showMapping]);

  const rows = useMemo(() => orderBy(data.filter(({
    type,
    district: d,
  }) => type === 'ward' && d === district), ['name'], ['asc']), [data]);

  return (
    <Paper elevation={3}>
      {checkMapping ? (
        <Mapping
          onClose={() => setCheckMapping()}
          onReload={() => dispatch(handleQuery({ type: 'ward', district }))}
          app={showMapping}
          source={rows}
          dest={Object.entries(
            // eslint-disable-next-line max-len
            mappingData?.[provinceData?.[showMapping]]?.districts?.[districtData?.[showMapping]]?.wards ?? {},
          ).map(([id, values]) => ({ id, name: values?.name ?? '' }))}
        />
      ) : null}
      <Box display="flex" p={1}>
        <Box flexGrow={1}>
          <Typography variant="h6" style={{ fontWeight: 'bold', padding: 5, textAlign: 'center' }}>Phường/Xã</Typography>
        </Box>
        {showMapping ? (
          <Button color="secondary" onClick={() => setCheckMapping(true)}>
            Kiểm tra mapping
          </Button>
        ) : null}
      </Box>
      <Table
        columns={columns}
        data={rows}
        disableFilters
        disableGroupBy
        getRowId={(row) => row.id}
        height={window.innerHeight - 340}
        usePagination={false}
      />
    </Paper>
  );
}

Wards.propTypes = {
  onEdit: PropTypes.func.isRequired,
  district: PropTypes.string.isRequired,
  showMapping: PropTypes.string,
};

Wards.defaultProps = {
  showMapping: '',
};

export default Wards;
