import React from 'react';

import { Box, Typography } from '@xbotvn/react-ui/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import packageJson from '../package.json';
import App from './App';
import rootSaga from './redux/actions';
import reducer from './redux/reducers';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

require('@xbotvn/react-ui');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //eslint-disable-line
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

ReactDOM.render((
  <Provider store={store}>
    <>
      <App />
      <Box
        zIndex={998}
        height={50}
        bgcolor="#394b59"
        color="#f5f8fa"
        p={1}
        position="fixed"
        right={0}
        bottom={0}
        left={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption">
          {`CATABOT v${packageJson.version} - © 2021, XBOT Technology JSC. All Rights Reserved.`}
        </Typography>
      </Box>
    </>
  </Provider>
), document.getElementById('root'));

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Đã có bản mới! Xin vui lòng ấn nút OK để cập nhật.')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
};
serviceWorkerRegistration.register(configuration);
