import React, { useEffect, useMemo } from 'react';

import { Notification, Waiting } from '@xbotvn/react-ui/components';
import { ThemeProvider, Themes } from '@xbotvn/react-ui/styles';
import { get } from '@xbotvn/utils/collection';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch as RouterSwitch,
} from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import { AppBar } from './components';
import { app } from './libs/firebase';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';
import {
  Cities,
  Welcome,
  NotFound,
} from './screens';

export default function App() {
  const dispatch = useDispatch();
  const {
    settings: { darkMode },
    authorizing,
  } = useSelector(({
    settings,
    user,
  }) => ({
    settings,
    authorizing: get(user, 'authorizing', false),
  }));

  useEffect(() => {
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) { document.body.style.backgroundColor = '#30404d'; }
    }
    return app.auth().onAuthStateChanged((user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  const ready = useMemo(() => !authorizing, [authorizing]);

  return ready ? (
    <ThemeProvider theme={Themes.default[darkMode ? 'dark' : 'light']}>
      <BrowserRouter>
        <AppBar />
        <RouterSwitch>
          <Route exact path="/"><Welcome /></Route>
          <AuthorizedRoute exact path="/cities" component={Cities} />
          <Route component={() => <NotFound />} />
        </RouterSwitch>
      </BrowserRouter>
      <Notification.Container />
    </ThemeProvider>
  ) : <Waiting fullscreen />;
}
